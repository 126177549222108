






















































































import { Component, Vue } from "vue-property-decorator";
import Layout from "@/components/Layout.vue"; // @ is an alias to /src
import AssignCard from "@/components/common/AssignCard.vue";
import MainSlogan from "@/components/common/MainSlogan.vue";
import PicWall from "@/components/common/PicWall.vue";
import Applyin from "@/components/common/Applyin.vue";
@Component({
  components: {
    Layout,
    AssignCard,
    MainSlogan,
    PicWall,
    Applyin,
  },
})
export default class ZeroCarbon extends Vue {
  sthree = require("@/assets/storage/img/sthree.png");
  arrawblack: string = require("@/assets/home/img/arrawblack.png");
  form = {
    name: "",
    region: "",
    date1: "",
    date2: "",
    delivery: false,
    type: [],
    resource: "",
    desc: "",
  };
  hbg: string = require("@/assets/zeroCarbon/img/bg.png");
  z1: string = require("@/assets/zeroCarbon/img/z1.png");
  z2: string = require("@/assets/zeroCarbon/img/z2.png");
  z3: string = require("@/assets/zeroCarbon/img/z3.png");
  z4: string = require("@/assets/zeroCarbon/img/z4.png");
  mobileArea = [
    {
      id: 1,
      image: this.z1,
      title: "让零碳走进你的生活",
      des: [
        "每个人都将同时具备用电者和发电者的双重身份，为每一个家庭减少电费支出，打造新型的低耗能生态住宅。",
      ],
    },
    {
      id: 2,
      image: this.z2,
      title: "让未来提前发生",
      des: [
        "以建筑能效管理为核心，建立起人与设备、设备与设备之间的智能互联，打造零碳智慧楼宇。有效提高建筑设备运行效率，降低用能成本，提升楼宇内人员的幸福感与舒适度，减少环境污染的同时助力建筑运营商实现双碳目标。",
      ],
    },
    {
      id: 3,
      image: this.z3,
      title: "零碳智慧产业园",
      des: [
        "能源不再无形地流动于设备之间，它可以被自由地吸收、储存和释放。从光伏发电到智慧储能全方位多维度帮助企业实现数字化零碳生产的转型，在园区内部基本实现碳排放与吸收自我平衡。",
      ],
    },
  ];
  goZChome() {
    this.$router.push({
      path: "/zeroCarbon/zchome",
    });
  }
  goZCBuilding() {
    this.$router.push({
      path: "/zeroCarbon/zchome",
    });
  }
  onSubmit() {
    console.log("submit!");
  }
}
